import React from 'react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { Stack } from '@chakra-ui/layout';

import { HeaderLogo, HeadingXL, Layout, SEO } from '../components';
import { Hero } from '../components/Hero';
import { Post } from '../components/Post';
import { TextHome } from '../components/TextHome';
import styled from 'styled-components';
import { BREAKPOINT } from '../utils/constants';
import { PostList } from '../components/PostList';
import { Box } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { ArrowLeft, ArrowRight } from '@styled-icons/icomoon';

const HeaderTile = styled.div`
  position: absolute;
  width: 100%;
  height: 360px;
  top: 80px;
  left: 0;
  z-index: -1;

  @media (min-width: ${BREAKPOINT}px) {
    height: 440px;
  }
`;

export default function Home({ data, pageContext }) {
  const { currentPage, numPages } = pageContext;
  const hasNextPage = currentPage < numPages;
  const hasPrevPage = currentPage > 1;
  const headerPicture = data.headerPicture.childImageSharp;
  const posts = data.posts.edges;
  const pagination = (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      align="center"
      justify="space-between"
      my={4}
    >
      <Box>
        {hasPrevPage && (
          <Button
            as={GatsbyLink}
            leftIcon={<ArrowLeft height="16" width="16" />}
            to={`/posts/${currentPage - 1}`}
            variant="outline"
          >
            Publicaciones más nuevas
          </Button>
        )}
      </Box>
      <Box>
        {hasNextPage && (
          <Button
            as={GatsbyLink}
            rightIcon={<ArrowRight height="16" width="16" />}
            to={`/posts/${currentPage + 1}`}
            variant="outline"
          >
            Publicaciones más antiguas
          </Button>
        )}
      </Box>
    </Stack>
  );
  return (
    <>
      <SEO title="Perspectiva Feminista" />
      <Layout>
        {currentPage === 1 ? (
          <>
            <HeaderTile>
              <div
                style={{
                  background: `url(${getSrc(headerPicture)})`,
                  backgroundRepeat: 'repeat',
                  backgroundSize: '800px',
                  height: '100%',
                  width: '100%',
                }}
              />
            </HeaderTile>
            <Hero>
              <HeadingXL>Perspectiva Feminista</HeadingXL>
              <TextHome>Miramos el pasado con gafas violetas</TextHome>
            </Hero>
          </>
        ) : (
          pagination
        )}
        <PostList>
          {posts.map(({ node: post }) => {
            return <Post key={post.frontmatter.title} post={post} />;
          })}
        </PostList>
        {pagination}
      </Layout>
      <HeaderLogo />
    </>
  );
}

export const postListQuery = graphql`
  query postListQuery($limit: Int!, $skip: Int!) {
    posts: allMarkdownRemark(
      filter: {
        frontmatter: { kind: { eq: "post" }, draft: { in: [false, null] } }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostPreview
        }
      }
    }

    headerPicture: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "index-tile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 800)
      }
    }
  }
`;
