import React from 'react';
import { Box } from '@chakra-ui/layout';

export const Hero = (props) => (
  <Box
    p={{ base: '16px', lg: '32px' }}
    background="var(--light-color-translucent)"
    boxShadow="base"
    my="40px"
    {...props}
  />
);
