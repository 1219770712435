import React from 'react';
import { SimpleGrid } from '@chakra-ui/layout';

export function PostList(props) {
  return (
    <SimpleGrid
      columns={{ base: 1, lg: 3 }}
      autoRows={{ base: '600px', lg: '600px' }}
      columnGap="2rem"
      rowGap="2rem"
      {...props}
    />
  );
}
