import React from 'react';
import { kebabCase } from 'lodash';

import { Link } from 'gatsby';
import { HeadingL } from './HeadingL';
import { TextMuted } from './TextMuted';
import { AuthorsSmall } from './AuthorsSmall';
import { Badge, Flex, HStack, Stack, Text, VStack } from '@chakra-ui/layout';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Clock } from '@styled-icons/evil/Clock';
import { Box } from '@chakra-ui/layout';

const Footer = Box;

export function Post({ post }) {
  const { date, title, epigraph, tags, authors, featuredImage } =
    post.frontmatter;
  const readingTimeMinutes = Math.round(post.fields.readingTime.minutes);
  return (
    <Stack
      as={Link}
      to={`/${kebabCase(title)}`}
      background="white"
      boxShadow="base"
    >
      <GatsbyImage
        style={{ height: '300px' }}
        image={getImage(featuredImage)}
      />

      <Stack p={6} justify="space-between" height="450px">
        <VStack align="flex-start">
          <VStack spacing={1} align="flex-start" width="100%">
            <HStack>
              {tags.map((tag) => (
                <Badge
                  as={Link}
                  variant="outline"
                  colorScheme="violet"
                  key={tag}
                  to={`/etiquetas/${kebabCase(tag)}`}
                >
                  {tag}
                </Badge>
              ))}
            </HStack>
            <Flex justify="space-between" width="100%">
              <TextMuted>
                <small>{date}</small>
              </TextMuted>
              <HStack spacing="2px">
                <Clock height="16" width="16" />
                <TextMuted>{readingTimeMinutes} minutos</TextMuted>
              </HStack>
            </Flex>
          </VStack>
          <HeadingL noOfLines={3}>{title}</HeadingL>
          <Text
            noOfLines={4}
            dangerouslySetInnerHTML={{ __html: epigraph || post.excerpt }}
          />
        </VStack>
        <Footer>{authors && <AuthorsSmall authors={authors} />}</Footer>
      </Stack>
    </Stack>
  );
}
