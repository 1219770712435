import React from 'react';
import { kebabCase } from 'lodash';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { TextMuted } from './TextMuted';
import { HStack, Wrap } from '@chakra-ui/layout';
import { WrapItem } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/layout';

export function AuthorsSmall({ authors }) {
  return (
    <TextMuted as={HStack} style={{ display: 'flex', alignItems: 'center' }}>
      <Box>Por</Box>
      <Wrap align="flex-start" spacing={{ base: 2, lg: 4 }}>
        {authors.map((author) => {
          const fullName = `${author.firstName} ${author.lastName}`;
          return (
            <WrapItem
              key={author.id}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <HStack
                as={Link}
                to={`/autores/${kebabCase(fullName)}`}
                spacing={1}
              >
                <GatsbyImage
                  image={getImage(author.profilePicture)}
                  style={{ borderRadius: '50%' }}
                />
                <TextMuted fontSize="sm">
                  <strong>{fullName}</strong>
                </TextMuted>
              </HStack>
            </WrapItem>
          );
        })}
      </Wrap>
    </TextMuted>
  );
}
