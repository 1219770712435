import React from 'react';
import { Text } from '@chakra-ui/layout';

export const TextHome = (props) => (
  <Text
    color="var(--dark-color-light)"
    fontSize={{ base: 'md', md: '2xl' }}
    fontWeight="500"
    textAlign="center"
    {...props}
  />
);
